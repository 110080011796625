<template>
    <div class="rtl overflow-y-scroll">
        <app-list
            ref="list"
            v-model="items"
            class="ltr"
            :searchable="!isInvestor()"
            :searching="searching"
            @search="onSearch"
        />
    </div>
</template>

<script>
import Sidebar from '@/sidebars/Sidebar';
import AppListItem from '@/models/AppListItem';
import { Offer, Product, Investor, Adviser } from '@/models';
import OfferWorkflowStatusEnum from '@/enums/offer/workflowStatus';

export default {
    name: 'LibrarySidebar',
    extends: Sidebar,
    data: () => ({
        items: [],
        searching: false
    }),
    async mounted() {
        await this.trySetup();

        await this.$nextTick();

        const activeEl = document.querySelector('.app-list__item--active');

        if (activeEl) {
            activeEl.scrollIntoView({
                behavior: 'smooth'
            });
        }
    },
    methods: {
        async setup() {
            this.items = [
                new AppListItem({
                    id: 'offers',
                    name: this.$t('offers'),
                    icon: this.getIcon('offers'),
                    route: {
                        name: 'Library',
                        params: { library: 'marketing' },
                        query: { type: 'offer' }
                    },
                    populate: () => async () => {
                        let items = (await Offer.api.index(null, null, null, this.getApiFilters('offers'))).data.map(
                            item => this.toAppListItem(item, 'offers')
                        );

                        if (this.$route.query.id) {
                            try {
                                const found = (await Offer.api.get(this.$route.query.id)).data;
                                const itemIndex = items.find(item => item.id === found.id);

                                if (found && itemIndex !== 0 && !itemIndex) {
                                    items.unshift(this.toAppListItem(found, 'offers'));
                                }
                            } catch (error) {
                                console.error(error);
                            }
                        }

                        return items;
                    }
                }),
                new AppListItem({
                    id: 'products',
                    name: this.$t('products'),
                    icon: this.getIcon('products'),
                    $disabled: true,
                    route: {
                        name: 'Library',
                        params: { library: 'marketing' },
                        query: { type: 'product' }
                    },
                    populate: () => async () => {
                        let items = (await Product.api.index()).data.map(item => this.toAppListItem(item, 'products'));

                        if (this.$route.query.id) {
                            try {
                                const found = (await Product.api.get(this.$route.query.id)).data;
                                const itemIndex = items.find(item => item.id === found.id);

                                if (found && itemIndex !== 0 && !itemIndex) {
                                    items.unshift(this.toAppListItem(found, 'products'));
                                }
                            } catch (error) {
                                console.error(error);
                            }
                        }

                        return items;
                    }
                }),
                new AppListItem({
                    id: 'investors',
                    name: this.$t('investors'),
                    icon: this.getIcon('investors'),
                    route: {
                        name: 'Library',
                        params: { library: 'investor' }
                    },
                    populate: () => async () => {
                        let items = (await Investor.api.index()).data.map(item =>
                            this.toAppListItem(item, 'investors')
                        );

                        if (this.$route.query.id) {
                            try {
                                const found = (await Investor.api.get(this.$route.query.id)).data;
                                const itemIndex = items.find(item => item.id === found.id);

                                if (found && itemIndex !== 0 && !itemIndex) {
                                    items.unshift(this.toAppListItem(found, 'investors'));
                                }
                            } catch (error) {
                                console.error(error);
                            }
                        }

                        return items;
                    }
                }),
                new AppListItem({
                    id: 'advisers',
                    name: this.$t('advisers'),
                    icon: this.getIcon('advisers'),
                    route: {
                        name: 'Library',
                        params: { library: 'adviser' }
                    },
                    populate: () => async () => {
                        let items = (await Adviser.api.index()).data.map(item => this.toAppListItem(item, 'advisers'));

                        if (this.$route.query.id) {
                            try {
                                const found = (await Adviser.api.get(this.$route.query.id)).data;
                                const itemIndex = items.find(item => item.id === found.id);

                                if (found && itemIndex !== 0 && !itemIndex) {
                                    items.unshift(this.toAppListItem(found, 'advisers'));
                                }
                            } catch (error) {
                                console.error(error);
                            }
                        }

                        return items;
                    }
                })
            ];

            this.items = await Promise.all(this.items.map(item => item.setup(true)));
        },
        getApi(id) {
            switch (id) {
                case 'offers':
                    return Offer.api;
                case 'products':
                    return Product.api;
                case 'investors':
                    return Investor.api;
                case 'advisers':
                    return Adviser.api;
                default:
                    return null;
            }
        },
        getApiFilters(id) {
            const isGI = this.isGrowthInvest();

            switch (id) {
                case 'offers':
                    if (isGI) {
                        return {
                            workflow_status: `${this.getFilterOperator('in')}:[${OfferWorkflowStatusEnum.DRAFT},${
                                OfferWorkflowStatusEnum.LIVE
                            }]`,
                            off_panel: true
                        };
                    } else {
                        return {};
                    }
                default:
                    return {};
            }
        },
        async onSearch(searchQuery) {
            this.searching = true;

            let items = [];

            if (searchQuery) {
                items = await Promise.all(
                    this.items.map(async item => {
                        if (item.$disabled || item.$readonly || item.$hidden) {
                            return item;
                        }

                        const api = this.getApi(item.id);
                        const filters = this.getApiFilters(item.id);

                        if (!api) {
                            return item;
                        }

                        item.items = (await api.search(searchQuery, filters)).data.map(result => {
                            return this.toAppListItem(result, item.id);
                        });

                        if (item.items.length) {
                            item.$expanded = true;
                        }

                        return item;
                    })
                );
            } else {
                items = await Promise.all(
                    this.items.map(async item => {
                        if (item.$disabled || item.$readonly || item.$hidden) {
                            return item;
                        }

                        const api = this.getApi(item.id);
                        const filters = this.getApiFilters(item.id);

                        if (!api) {
                            return item;
                        }

                        item.items = (await api.index(null, null, null, filters)).data.map(result => {
                            return this.toAppListItem(result, item.id);
                        });

                        if (item.items.length) {
                            item.$expanded = true;
                        }

                        return item;
                    })
                );
            }

            this.items = await Promise.all(items.map(item => item.setup(true)));

            this.searching = false;
        },
        toAppListItem(value, type) {
            if (type === 'offers') {
                return new AppListItem({
                    id: value.id,
                    name: value.name,
                    route: {
                        name: 'Library',
                        params: { library: 'marketing' },
                        query: { type: 'offer', id: value.id }
                    }
                });
            } else if (type === 'products') {
                return new AppListItem({
                    id: value.id,
                    name: value.name,
                    route: {
                        name: 'Library',
                        params: { library: 'marketing' },
                        query: { type: 'product', id: value.id }
                    }
                });
            } else if (type === 'investors') {
                return new AppListItem({
                    id: value.id,
                    name: value.name,
                    description: value.email,
                    tooltip: value.gi_ref,
                    route: {
                        name: 'Library',
                        params: { library: 'investor' },
                        query: { id: value.id }
                    }
                });
            } else if (type === 'advisers') {
                return new AppListItem({
                    id: value.id,
                    name: value.name,
                    tooltip: value.gi_ref,
                    route: {
                        name: 'Library',
                        params: { library: 'adviser' },
                        query: { id: value.id }
                    }
                });
            }
        }
    }
};
</script>
