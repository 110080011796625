import { Collection, Holding } from '@/models';
import i18n from '@/lib/i18n';
import TaxStatusEnum from '@/enums/taxStatus';
import HoldingDispositionResultEnum from '@/enums/holding/dispositionResult';
import HoldingStatusEnum from '@/enums/holding/status';

export class IntegrationHoldingCollection extends Collection {
    static type = 'IntegrationHoldingCollection';
    static endpoint = Holding.api.index;
    static model = Holding;

    get money_format_options() {
        return {
            decimals: 2,
            abbreviate: false
        };
    }

    get percentage_format_options() {
        return {
            abbreviate: false,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        };
    }

    get preset_filters() {
        return {
            tranche_id: this.filterOperator('empty')
        };
    }

    get headers() {
        return [
            this.createHeader('gi_ref', i18n.t('gi_ref'), {
                width: '8rem',
                filterable: true
            }),
            this.createHeader('name', i18n.t('name'), {
                width: '18rem',
                minWidth: '18rem',
                filterable: false,
                sortable: true
            }),
            this.createHeader('investor_name', i18n.t('investor') + ' ' + i18n.t('name'), {
                width: '11rem',
                transform: data => data.item?.investor?.name,
                sortable: true,
                filterable: true
            }),
            this.createHeader('investor_id', i18n.t('investor'), {
                active: false,
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Investor')
                }
            }),
            this.createHeader('adviser_name', i18n.t('adviser') + ' ' + i18n.t('name'), {
                width: '11rem',
                transform: data => data.item?.adviser?.name,
                sortable: true,
                filterable: true
            }),
            this.createHeader('adviser_id', i18n.t('adviser'), {
                active: false,
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Adviser')
                }
            }),
            this.createHeader('product_id', i18n.t('product'), {
                active: false,
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Product')
                }
            }),
            this.createHeader('tax_year', i18n.t('tax_year'), {
                width: '7.5rem',
                transform: data => this.getTaxYear(data.item.tax_year, { abbreviate: true }),
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/TaxYear')
                },
                fallback: data => {
                    if (data.item.pending_allotment) {
                        return i18n.t('pending');
                    }

                    return '-';
                },
                sortable: true
            }),
            this.createHeader('tax_status', i18n.t('tax_status'), {
                format: 'enum',
                filterable: true,
                enum: TaxStatusEnum,
                enumA11yKey: 'enums.tax_status_short',
                formatOptions: {
                    tooltip: v => i18n.t(`enums.tax_status.${v}`)
                },
                width: '8rem',
                sortable: true
            }),
            this.createHeader('purchased_on', i18n.t('purchased_on'), {
                width: '6rem',
                format: 'date',
                filterable: true,
                sortable: true
            }),
            this.createHeader('disposition_on', i18n.t('disposition_on'), {
                active: false,
                width: '7rem',
                format: 'date',
                filterable: true
            }),
            this.createHeader('disposition_result', i18n.t('disposition_result'), {
                active: false,
                format: 'enum',
                filterable: true,
                enum: HoldingDispositionResultEnum,
                enumA11yKey: 'enums.disposition_result',
                width: '7rem'
            }),
            this.createHeader('disposition_tax_year', i18n.t('disposition_tax_year'), {
                active: false,
                width: '7.5rem',
                transform: data => this.getTaxYear(data.item.disposition_tax_year, { abbreviate: true }),
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/TaxYear')
                }
            }),
            this.createHeader('moic', i18n.t('moic'), {
                active: false,
                format: 'number',
                formatOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                append: 'X',
                width: '4.2rem',
                sortable: false,
                filterable: true
            }),
            this.createHeader('failure_on', i18n.t('failure_on'), {
                active: false,
                width: '6rem',
                format: 'date',
                filterable: true
            }),
            this.createHeader('share_certificate_received_on', i18n.t('share_certificate_received_on'), {
                active: false,
                width: '10rem',
                format: 'date',
                filterable: true
            }),
            this.createHeader('eis_certificate_received_on', i18n.t('eis_certificate_received_on'), {
                active: false,
                width: '10rem',
                format: 'date',
                filterable: true
            }),
            this.createHeader('exit_on', i18n.t('exit_on'), {
                active: false,
                width: '7.4rem',
                format: 'date',
                filterable: true
            }),
            this.createHeader('share_class', i18n.t('share_class'), {
                active: false,
                width: '8rem',
                filterable: true
            }),
            this.createHeader('application_on', i18n.t('application_on'), {
                active: false,
                width: '7rem',
                format: 'date',
                filterable: true
            }),
            this.createHeader('share_quantity', i18n.t('share_quantity'), {
                width: '8rem',
                format: 'number',
                filterable: true,
                sortable: true
            }),
            this.createHeader('single_company', i18n.t('single_company'), {
                active: false,
                width: '8rem',
                format: 'boolean',
                filterable: true
            }),
            this.createHeader('status', i18n.t('status'), {
                active: false,
                format: 'enum',
                width: '7rem',
                filterable: true,
                enum: HoldingStatusEnum,
                enumA11yKey: 'enums.holding_status',
                enumTypes: {
                    [HoldingStatusEnum.DRAFT]: 'grey',
                    [HoldingStatusEnum.OPEN]: 'info',
                    [HoldingStatusEnum.CLOSED]: 'error'
                }
            }),
            this.createHeader('reconciled', i18n.t('verified'), {
                active: false,
                filterable: true,
                format: 'boolean',
                width: '6rem'
            }),
            this.createHeader('pending_allotment', i18n.t('pending_allotment'), {
                active: false,
                format: 'boolean',
                filterable: {
                    operators: this.getOperators(['eq'])
                }
            }),
            this.createHeader('share_issue_price', i18n.t('share_issue_price'), {
                width: '7rem',
                format: 'money',
                formatOptions: super.money_format_options,
                filterable: true,
                sortable: true
            }),
            this.createHeader('share_issue_on', i18n.t('share_issue_on'), {
                active: false,
                width: '7rem',
                format: 'date',
                align: 'right',
                filterable: true
            }),
            this.createHeader('invested_amount', i18n.t('invested_amount'), {
                width: '8rem',
                format: 'money',
                formatOptions: this.money_format_options,
                filterable: true,
                sortable: true
            }),
            this.createHeader('tranche_id', i18n.t('tranche'), {
                active: false,
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Tranche')
                }
            }),
            this.createHeader('id', i18n.t('holding'), {
                active: false,
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Holding')
                }
            }),
            this.createHeader('company_id', i18n.t('company'), {
                active: false,
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Company')
                }
            }),
            this.createHeader('is_fund', i18n.t('is_fund'), {
                active: false,
                format: 'boolean',
                filterable: {
                    operators: this.getOperators(['eq'])
                }
            }),
            this.createHeader('fund_id', i18n.t('fund'), {
                active: false,
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Fund')
                }
            }),
            this.createHeader('company_name', i18n.t('company_name'), {
                active: false,
                filterable: true
            })
        ];
    }
}

export default IntegrationHoldingCollection;
