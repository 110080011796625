import permissions from '@/enums/auth/permission';
import i18n from '@/lib/i18n';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import map from 'lodash/map';
import kebabCase from 'lodash/kebabCase';
import snakeCase from 'lodash/snakeCase';

import { tabs } from '@/views/PortfolioDetail';

export default [
    {
        path: '/portfolio',
        name: 'Portfolio',
        component: () =>
            import(
                /* webpackChunkName: "portfolio" */
                '@/views/Portfolio.vue'
            ),
        redirect: {
            name: 'PortfolioDashboard'
        },
        children: [
            {
                path: 'dashboard',
                name: 'PortfolioDashboard',
                component: () =>
                    import(
                        /* webpackChunkName: "portfolio" */
                        '@/views/PortfolioDashboard.vue'
                    ),
                meta: {
                    title: i18n.t('_page.portfolio_dashboard.heading'),
                    heading: i18n.t('_page.portfolio_dashboard.heading'),
                    description: i18n.t('_page.portfolio_dashboard.description'),
                    organisations: ['ADVISER', 'INVESTOR'],
                    retainQuerySelectors: ['investor_id']
                }
            },
            {
                path: 'detail',
                name: 'PortfolioDetail',
                component: () =>
                    import(
                        /* webpackChunkName: "portfolio" */
                        '@/views/PortfolioDetail.vue'
                    ),
                redirect: {
                    name: 'PortfolioDetail' + upperFirst(camelCase(tabs[0]))
                },
                meta: {
                    title: i18n.t('_page.portfolio_detail.heading'),
                    heading: i18n.t('_page.portfolio_detail.heading'),
                    description: i18n.t('see_tabs_of_all_display_sections'),
                    organisations: ['ADVISER', 'INVESTOR'],
                    retainQuerySelectors: ['investor_id']
                },
                children: map(tabs, item => {
                    return {
                        path: kebabCase(item),
                        name: 'PortfolioDetail' + upperFirst(camelCase(item)),
                        component: () => import('@/views/PortfolioDetail' + upperFirst(camelCase(item)) + '.vue'),
                        meta: {
                            title:
                                i18n.t('_page.portfolio_detail.heading') +
                                ' - ' +
                                i18n.t('_page.portfolio_detail.' + snakeCase(item) + '.heading'),
                            heading: i18n.t('_page.portfolio_detail.' + snakeCase(item) + '.heading'),
                            overline: i18n.t('_page.portfolio_detail.heading'),
                            organisations: ['ADVISER', 'INVESTOR'],
                            retainQuerySelectors: ['investor_id']
                        }
                    };
                })
            },
            {
                path: 'client-registrations',
                name: 'PortfolioClientRegistrations',
                component: () =>
                    import(
                        /* webpackChunkName: "portfolio" */
                        '@/views/PortfolioClientRegistrations.vue'
                    ),
                meta: {
                    title: i18n.t('_page.client_registrations.heading'),
                    heading: i18n.t('_page.client_registrations.heading'),
                    permissions: [permissions.INVESTOR_READ],
                    organisations: ['ADVISER'],
                    retainQuerySelectors: ['investor_id']
                }
            },
            {
                path: 'clients',
                name: 'PortfolioClients',
                component: () =>
                    import(
                        /* webpackChunkName: "portfolio" */
                        '@/views/PortfolioClients.vue'
                    ),
                meta: {
                    title: i18n.t('_page.clients.heading'),
                    heading: i18n.t('_page.clients.heading'),
                    permissions: [permissions.INVESTOR_READ],
                    organisations: ['ADVISER']
                }
            }
        ]
    },
    {
        path: '/accounts',
        name: 'Accounts',
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "portfolio" */
                    '@/views/Accounts.vue'
                )
        },
        meta: {
            title: i18n.t('cash_and_fees'),
            heading: i18n.t('cash_and_fees'),
            organisations: ['ADVISER', 'INVESTOR'],
            retainQuerySelectors: ['investor_id']
        }
    },
    {
        path: '/transactions',
        name: 'Transactions',
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "portfolio" */
                    '@/views/Transactions.vue'
                ),
            sidebar: () =>
                import(
                    /* webpackChunkName: "portfolio" */
                    '@/sidebars/TransactionsSidebar.vue'
                )
        },
        meta: {
            title: i18n.t('transactions'),
            heading: i18n.t('transactions'),
            organisations: ['ADVISER', 'INVESTOR'],
            retainQuerySelectors: ['investor_id'],
            sidebarKey: 'transactions-sidebar'
        }
    }
];
