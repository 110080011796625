import FilterOperator from '@/enums/filter/operator';

const FieldTypeEnum = Object.freeze({
    STRING: {
        operators: [
            FilterOperator.CONTAINS,
            FilterOperator.NOT_CONTAINS,
            FilterOperator.STARTS_WITH,
            FilterOperator.ENDS_WITH,
            FilterOperator.IS,
            FilterOperator.IS_NOT,
            FilterOperator.EMPTY,
            FilterOperator.NOT_EMPTY
            // FilterOperator.IN,
            // FilterOperator.NOT_IN
        ]
    },
    NUMBER: {
        operators: [
            FilterOperator.IS,
            FilterOperator.IS_NOT,
            FilterOperator.LESS_THAN_OR_IS,
            FilterOperator.MORE_THAN_OR_IS,
            FilterOperator.EMPTY,
            FilterOperator.NOT_EMPTY
            // FilterOperator.IN,
            // FilterOperator.NOT_IN
        ]
    },
    MONEY: {
        operators: [
            FilterOperator.IS,
            FilterOperator.IS_NOT,
            FilterOperator.LESS_THAN_OR_IS,
            FilterOperator.MORE_THAN_OR_IS,
            FilterOperator.EMPTY,
            FilterOperator.NOT_EMPTY
            // FilterOperator.IN,
            // FilterOperator.NOT_IN
        ]
    },
    DATE: {
        operators: [
            FilterOperator.IS,
            FilterOperator.IS_NOT,
            FilterOperator.EMPTY,
            FilterOperator.NOT_EMPTY,
            FilterOperator.IS_BEFORE,
            FilterOperator.IS_AFTER
        ]
    },
    DATETIME: {
        operators: [
            FilterOperator.IS,
            FilterOperator.IS_NOT,
            FilterOperator.EMPTY,
            FilterOperator.NOT_EMPTY,
            FilterOperator.IS_BEFORE,
            FilterOperator.IS_AFTER
        ]
    },
    PERCENTAGE: {
        operators: [
            FilterOperator.IS,
            FilterOperator.IS_NOT,
            FilterOperator.LESS_THAN_OR_IS,
            FilterOperator.MORE_THAN_OR_IS,
            FilterOperator.EMPTY,
            FilterOperator.NOT_EMPTY
            // FilterOperator.IN,
            // FilterOperator.NOT_IN
        ]
    },
    BOOLEAN: {
        operators: [FilterOperator.IS, FilterOperator.EMPTY, FilterOperator.NOT_EMPTY]
    },
    WYSIWYG: {
        operators: [
            FilterOperator.CONTAINS,
            FilterOperator.NOT_CONTAINS,
            FilterOperator.EMPTY,
            FilterOperator.NOT_EMPTY
        ]
    },
    ENUM: {
        operators: [
            FilterOperator.IS,
            FilterOperator.IS_NOT,
            FilterOperator.EMPTY,
            FilterOperator.NOT_EMPTY,
            FilterOperator.IN,
            FilterOperator.NOT_IN
        ]
    },
    CONTROL: {
        operators: []
    }
});

export default FieldTypeEnum;
