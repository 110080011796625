import { BaseModel, Adviser, Investor, CustodyAccount, Fund } from '@/models';
import AccountTypeEnum from '@/enums/account/type';
import AccountStatusEnum from '@/enums/account/status';
import AdviserApi from '@/api/AdviserApi';
import InvestorApi from '@/api/InvestorApi';
import FundApi from '@/api/FundApi';
import AccountApi from '@/api/AccountApi';
import getIcon from '@/lib/helpers/getIcon';
import i18n from '@/lib/i18n';
import toCurrency from '@/lib/helpers/toCurrency';

export class Account extends BaseModel {
    static entity = 'accounts';
    static Api = AccountApi;

    static fields() {
        return {
            id: this.string(null).nullable(),
            type: this.enum(AccountTypeEnum).nullable(),
            status: this.enum(AccountStatusEnum).nullable(),
            name: this.string(null).nullable(),
            currency: this.string(null).nullable(),
            gi_ref: this.string(null).nullable(),
            custody_accounts: this.modelList(CustodyAccount, null).nullable(),

            balance: this.attr(null).nullable(),
            balance_updated_at: this.string(null).nullable(),

            platform_fees_balance: this.attr(null).nullable(),
            adviser_initial_fees_balance: this.attr(null).nullable(),
            adviser_ongoing_fees_balance: this.attr(null).nullable(),
            total_platform_fees: this.attr(null).nullable(),
            total_adviser_initial_fees: this.attr(null).nullable(),
            total_adviser_ongoing_fees: this.attr(null).nullable(),
            total_fee_reserve: this.attr(null).nullable(),
            platform_reserve: this.attr(null).nullable(),
            adviser_ongoing_reserve: this.attr(null).nullable(),

            reconciled_balance: this.attr(null).nullable(),
            reconciled_balance_updated_at: this.string(null).nullable(),

            adviser_id: this.string(null).nullable(),
            adviser: this.belongsTo(Adviser, 'adviser_id'),

            investor_id: this.string(null).nullable(),
            investor: this.belongsTo(Investor, 'investor_id'),

            fund_id: this.string(null).nullable(),
            fund: this.belongsTo(Fund, 'fund_id')
        };
    }

    static mock() {
        return {
            id: faker => faker.string.uuid(),
            type: faker => faker.helpers.arrayElement(Object.keys(AccountTypeEnum)),
            status: faker => faker.helpers.arrayElement(Object.keys(AccountStatusEnum)),
            name: faker => faker.company.name(),
            currency: faker => faker.finance.currencyCode(),
            gi_ref: faker => faker.finance.accountNumber(),
            custody_accounts: [],
            balance: (faker, item) => ({
                amount: faker.finance.amount({ min: 50000, max: 1000000, dec: 2 }),
                currency: item.currency
            }),
            balance_updated_at: faker => faker.date.past().toISOString(),
            reconciled_balance: (faker, item) => ({
                amount: faker.finance.amount({ min: 50000, max: 1000000, dec: 2 }),
                currency: item.currency
            }),
            reconciled_balance_updated_at: faker => faker.date.past().toISOString(),

            adviser: AdviserApi,
            adviser_id: (faker, item) => item.adviser.id,

            investor: InvestorApi,
            investor_id: (faker, item) => item.investor.id,

            fund: FundApi,
            fund_id: (faker, item) => item.fund.id
        };
    }

    get is_type_platform() {
        return this.type === AccountTypeEnum.PLATFORM;
    }

    get is_type_fund() {
        return this.type === AccountTypeEnum.FUND;
    }

    get is_type_isa() {
        return this.type === AccountTypeEnum.ISA;
    }

    get is_type_isa_fund() {
        return this.type === AccountTypeEnum.ISAFUND;
    }

    get is_type_fee() {
        return this.type === AccountTypeEnum.FEE;
    }

    get is_status_active() {
        return this.status === AccountStatusEnum.ACTIVE;
    }

    get is_status_dormant() {
        return this.status === AccountStatusEnum.DORMANT;
    }

    get is_status_suspended() {
        return this.status === AccountStatusEnum.SUSPENDED;
    }

    get is_status_closed() {
        return this.status === AccountStatusEnum.CLOSED;
    }

    get custody_account() {
        if (!this.custody_accounts) {
            return null;
        }

        return this.custody_accounts[0];
    }

    get icon() {
        if (this.is_type_platform) {
            return getIcon('platform', 'far');
        } else if (this.is_type_fund) {
            return getIcon('fund', 'far');
        } else if (this.is_type_isa) {
            return getIcon('shield', 'fas');
        } else if (this.is_type_isa_fund) {
            return getIcon('buildingShield', 'far');
        } else if (this.is_type_fee) {
            return getIcon('fee', 'far');
        }

        return null;
    }

    get type_name() {
        if (!this.type) {
            return null;
        }

        return i18n.t(`enums.account_type.${this.type}`);
    }

    get pretty_balance() {
        const amount = this.balance && this.balance.amount !== null ? this.balance.amount : 0;
        const currency = this.balance.currency || this.currency || 'GBP';

        return toCurrency(amount, currency);
    }

    async $getBalances(options = {}) {
        options = {
            filters: {},
            ...options
        };

        return (await Account.api.getBalances(this.id, options.filters)) || [];
    }
}

export default Account;
