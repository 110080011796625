import { Collection, Activity } from '@/models';
import i18n from '@/lib/i18n';
import ActivityScopeEnum from '@/enums/activity/scope';
import toCurrency from '@/lib/helpers/toCurrency';

export class ActivityCollection extends Collection {
    static type = 'ActivityCollection';
    static endpoint = Activity.api.index;
    static model = Activity;

    get default_headers() {
        return [
            'activity',
            'created_at',
            'scope',
            'user_id',
            'user_email',
            'investor_id',
            'adviser_id',
            'offer_id',
            'product_id',
            'transaction_id',
            'shortlist_id',
            'proposal_id',
            'fund_id',
            'created_by_id'
        ];
    }

    get pdf_headers() {
        return [
            'title',
            'created_at',
            'text',
            'type',
            'scope',
            'todo',
            'user_id',
            'user_email',
            'investor_id',
            'adviser_id',
            'offer_id',
            'product_id',
            'transaction_id',
            'shortlist_id',
            'proposal_id',
            'fund_id',
            'created_by_id'
        ];
    }

    get headers() {
        return [
            this.createHeader('activity', i18n.t('activity'), {
                width: '35rem',
                filterable: false,
                sortable: false
            }),
            this.createHeader('title', i18n.t('activity'), {
                width: '12rem',
                filterable: true
            }),
            this.createHeader('created_at', i18n.t('created_at'), {
                width: '9rem',
                filterable: true,
                sortable: true,
                format: 'datetime',
                formatOptions: {
                    showTime: true
                }
            }),
            this.createHeader('text', i18n.t('text'), {
                width: '38rem',
                filterable: true
            }),
            this.createHeader('scope', i18n.t('scope'), {
                filterable: true,
                width: '6.2rem',
                format: 'enum',
                enum: ActivityScopeEnum,
                enumA11yKey: 'enums.activity_scope'
            }),
            this.createHeader('user_id', i18n.t('user'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/User')
                },
                transform: data => data.item?.user?.name
            }),
            this.createHeader('user_email', i18n.t('user_email'), {
                width: '14rem',
                transform: data => data.item?.user?.email,
                classList: ['text--secondary', 'text--small'],
                filterable: true
            }),
            this.createHeader('investor_id', i18n.t('investor'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Investor')
                },
                transform: data => data.item?.investor?.name
            }),
            this.createHeader('adviser_id', i18n.t('adviser'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Adviser')
                },
                transform: data => data.item?.adviser?.name
            }),
            this.createHeader('offer_id', i18n.t('offer'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Offer'),
                    filters: {
                        closed: true,
                        off_panel: true
                    }
                },
                transform: data => data.item?.offer?.name
            }),
            this.createHeader('product_id', i18n.t('product'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Product')
                },
                transform: data => data.item?.product?.name
            }),
            this.createHeader('transaction_id', i18n.t('transaction'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Transaction')
                },
                transform: data => data.item?.transaction?.gi_ref
            }),
            this.createHeader('shortlist_id', i18n.t('shortlist'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Shortlist')
                },
                transform: data => data.item?.shortlist?.name
            }),
            this.createHeader('proposal_id', i18n.t('proposal'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Proposal')
                },
                transform: data => {
                    let name = [];

                    if (data.item?.proposal?.offer?.name) {
                        name.push(data.item?.proposal?.offer?.name);
                    }

                    if (data.item?.proposal?.amount?.amount) {
                        name.push(toCurrency(data.item.proposal.amount.amount, data.item.proposal.amount.currency));
                    }

                    name = name.join(' - ');

                    return name ? name : null;
                }
            }),
            this.createHeader('fund_id', i18n.t('fund'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Fund')
                },
                transform: data => data.item?.fund?.name
            }),
            this.createHeader('created_by_id', i18n.t('created_by'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/User')
                },
                transform: data => data.item?.created_by?.name,
                fallback: i18n.t('system')
            }),
            this.createHeader('ip_address', i18n.t('source_ip_address'), {
                width: '18rem',
                filterable: false
            })
        ];
    }
}

export default ActivityCollection;
