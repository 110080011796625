import BaseApi from '@/api/BaseApi';

export class ProposalApi extends BaseApi {
    static resource = 'proposals';

    get endpoints() {
        return {
            ...super.endpoints,
            accept: this.accept.bind(this),
            getApplicationForm: this.getApplicationForm.bind(this),
            cancelApplicationForm: this.cancelApplicationForm.bind(this),
            resendApplicationForm: this.resendApplicationForm.bind(this),
            generateRemoteApplication: this.generateRemoteApplication.bind(this)
        };
    }

    _filterResponseData(data) {
        data = super._filterResponseData(data);

        if (data && Array.isArray(data)) {
            data = data.map(item => this._filterResponseData(item));
        } else if (data && typeof data === 'object' && data.investments) {
            data.investments.sort((a, b) => {
                const aName = (a.product?.name || a.fund?.name || '').toLowerCase();
                const bName = (b.product?.name || b.fund?.name || '').toLowerCase();
                return aName.localeCompare(bName);
            });
        }

        return data;
    }

    async accept(proposalId) {
        const response = await BaseApi.api.post(`${this.base_url}/${proposalId}/accept`);
        return this._respond(response, () => {
            this._updateItemInCollections(proposalId, response.data);
            this._insertModel(response.data, this.ApiResponseFormatEnum.DETAIL);
        });
    }

    async getApplicationForm(proposalId) {
        let url = `${this.base_url}`;

        if (proposalId) {
            url += `/${proposalId}`;
        }

        url += '/application-form';

        return await BaseApi.api.get(
            url,
            {},
            {
                headers: {
                    Accept: 'application/pdf'
                },
                responseType: 'blob',
                returnFullResponse: true
            }
        );
    }

    async cancelApplicationForm(proposalId) {
        const response = await BaseApi.api.post(`${this.base_url}/${proposalId}/application-form/cancel`);
        return this._respond(response);
    }

    async resendApplicationForm(proposalId) {
        const response = await BaseApi.api.post(`${this.base_url}/${proposalId}/application-form/resend`);
        return this._respond(response);
    }

    async generateRemoteApplication(proposalId, data = {}) {
        const response = await BaseApi.api.post(`${this.base_url}/${proposalId}/generate-remote-application`, data);
        return this._respond(response);
    }
}

export default ProposalApi;
