import { Collection, Tranche } from '@/models';
import i18n from '@/lib/i18n';
import TaxStatusEnum from '@/enums/taxStatus';

export class IntegrationTrancheCollection extends Collection {
    static type = 'IntegrationTrancheCollection';
    static endpoint = Tranche.api.index;
    static model = Tranche;

    get money_format_options() {
        return {
            decimals: 2,
            abbreviate: false
        };
    }

    get percentage_format_options() {
        return {
            abbreviate: false,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        };
    }

    get headers() {
        return [
            this.createHeader('id', i18n.t('id'), {
                active: false,
                filterable: true
            }),
            this.createHeader('gi_ref', i18n.t('gi_ref'), {
                width: '8rem',
                filterable: true
            }),
            this.createHeader('created_at', i18n.t('created_at'), {
                active: false,
                format: 'date'
            }),
            this.createHeader('fund_name', i18n.t('name'), {
                path: 'fund.name',
                width: '18rem',
                minWidth: '18rem',
                sortable: true
            }),
            this.createHeader('investor_name', i18n.t('investor') + ' ' + i18n.t('name'), {
                width: '11rem',
                transform: data => data.item?.investor?.name,
                sortable: true,
                filterable: true
            }),
            this.createHeader('investor_id', i18n.t('investor'), {
                active: false,
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Investor')
                }
            }),
            this.createHeader('adviser_name', i18n.t('adviser') + ' ' + i18n.t('name'), {
                width: '11rem',
                transform: data => data.item?.adviser?.name,
                sortable: true,
                filterable: true
            }),
            this.createHeader('adviser_id', i18n.t('adviser'), {
                active: false,
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Adviser')
                }
            }),
            this.createHeader('fund_id', i18n.t('fund'), {
                active: false,
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Fund')
                }
            }),
            this.createHeader('reconciled', i18n.t('verified'), {
                active: false,
                format: 'boolean',
                width: '9rem',
                filterable: true
            }),
            this.createHeader('discretionary', i18n.t('discretionary'), {
                active: false,
                format: 'boolean',
                width: '9rem',
                filterable: true
            }),
            this.createHeader('multi_investment', i18n.t('multi_investment'), {
                active: false,
                format: 'boolean',
                width: '10rem',
                filterable: true
            }),
            this.createHeader('cash_balance', i18n.t('cash_balance'), {
                active: false,
                format: 'money',
                formatOptions: this.money_format_options,
                width: '9rem',
                filterable: true
            }),
            this.createHeader('invested_on', i18n.t('invested_on'), {
                format: 'date',
                width: '9rem',
                filterable: true,
                sortable: true
            }),
            this.createHeader('invested_amount', i18n.t('invested_amount'), {
                format: 'money',
                formatOptions: this.money_format_options,
                width: '11rem',
                filterable: true,
                sortable: true
            }),
            this.createHeader('purchased_on', i18n.t('purchased_on'), {
                active: false,
                width: '6rem',
                format: 'date',
                filterable: true
            }),
            this.createHeader('total_invested', i18n.t('total_invested'), {
                width: '9rem',
                format: 'money',
                formatOptions: this.money_format_options,
                filterable: true,
                sortable: true
            }),
            this.createHeader('current_value', i18n.t('current_value'), {
                active: false,
                format: 'money',
                formatOptions: this.money_format_options,
                width: '11rem',
                filterable: true
            }),
            this.createHeader('total_return', i18n.t('total_return'), {
                active: false,
                format: 'money',
                formatOptions: this.money_format_options,
                filterable: true
            }),
            this.createHeader('total_return_moic', i18n.t('total_return_moic'), {
                active: false,
                format: 'number',
                formatOptions: {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                append: 'X',
                filterable: true
            }),
            this.createHeader('total_return_percentage', i18n.t('total_return_percentage'), {
                active: false,
                column: 'total_return_percentage',
                format: 'percentage',
                formatOptions: this.percentage_format_options,
                width: '9.2rem',
                filterable: true
            }),
            this.createHeader('total_return_including_tax_relief', i18n.t('total_return_including_tax_relief'), {
                active: false,
                format: 'money',
                formatOptions: this.money_format_options,
                filterable: true
            }),
            this.createHeader(
                'total_return_including_tax_relief_moic',
                i18n.t('total_return_including_tax_relief_moic'),
                {
                    active: false,
                    format: 'number',
                    formatOptions: {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    },
                    append: 'X',
                    filterable: true
                }
            ),
            this.createHeader(
                'total_return_including_tax_relief_percentage',
                i18n.t('total_return_including_tax_relief_percentage'),
                {
                    active: false,
                    format: 'percentage',
                    formatOptions: this.percentage_format_options,
                    filterable: true
                }
            ),

            this.createHeader('tax_year', i18n.t('tax_year'), {
                active: false,
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/TaxYear')
                }
            }),
            this.createHeader('tax_status', i18n.t('tax_status'), {
                active: false,
                format: 'enum',
                filterable: true,
                enum: TaxStatusEnum,
                enumA11yKey: 'enums.tax_status_short'
            })
        ];
    }
}

export default IntegrationTrancheCollection;
