import store from '@/store';

const resetQuerySelectors = (to, from, next) => {
    const retained = to.meta?.retainQuerySelectors || [];

    if (retained.length) {
        // Remove query selectors not included in retained list
        const querySelectorKeys = store.getters['app/query_selector_keys'];

        querySelectorKeys.forEach(key => {
            if (!retained.includes(key)) {
                store.dispatch('app/removeQuerySelector', key);
            }
        });

        next();
    } else {
        // Reset query selectors to default state
        store.dispatch('app/removeQuerySelectors');
        next();
    }
};

export default resetQuerySelectors;
