<template>
    <transition appear name="fade-transition">
        <template v-if="$route.name === 'Reports'">
            <app-lacuna icon="reports" :title="$t('reports')" :text="$t('please_select_an_item_from_the_menu')" />
        </template>
        <router-view v-else />
    </transition>
</template>

<script>
export const tabKeys = [
    'adviser_documents_activity',
    'adviser_marketing_activity',
    'adviser_security_activity',
    'auth_activity',
    'client_portfolio',
    'client_summary',
    'clients_custodial_accounts',
    'disposals',
    'disposals_failures',
    'disposals_sales',
    'dividend_transactions',
    'holdings',
    'integration_holdings',
    'integration_tranches',
    'investments',
    'investments_eis_seis',
    'investments_iht',
    'investments_pmi',
    'investments_single_company',
    'investments_vct',
    'investor_activity',
    'investor_documents_activity',
    'investor_flow_activity',
    'investor_marketing_activity',
    'investor_security_activity',
    'negligible_value',
    'pending_applications',
    'pmi_tranches',
    'position_reconciliation',
    'proposals',
    'registrations',
    'tax_year',
    'tranches',
    'transaction_reconciliation',
    'transactions_cash_deposits',
    'transactions_cash_withdrawals',
    'transactions_fee',
    'transactions_income',
    'vct_maturity'
];

export default {
    name: 'Reports',
    async mounted() {
        document.documentElement.style.overflow = 'hidden';
        document.body.style.overflow = 'hidden';

        let breadcrumbs = [];

        if (this.$route.name !== 'Reports') {
            breadcrumbs.push({
                heading: this.$t('reports'),
                to: { name: 'Reports' }
            });
        }

        breadcrumbs.push({
            heading: this.$route.meta.heading
        });

        this.setBreadcrumbs(breadcrumbs);
    },
    beforeDestroy() {
        document.documentElement.style.overflow = null;
        document.body.style.overflow = null;
    }
};
</script>
